<template>
    <div class="main">
        <nav class="navbar">
            <div class="navbar-header">
                <a class="">
	            	<img class="navbar-logo" src="http://www.cxcyedu.com/assets/images/logo.png">
		        	<!-- <img class="navbar-brand-logo navbar-brand-logo-special" src="/assets/images/logo-colored.png"> -->
		        	<span class="navbar-text">创新创业竞赛云平台场控端</span>
		        </a>
            </div>
            <div class="navbar-content">
                <ul>
                    <li class="el-icon-full-screen" @click="handleFullScreen"></li>
                    <li>
                         <el-popover placement="bottom"  width="0" trigger="click">
                           <ul>
                             <li class="yincang"><i class="el-icon-user-solid"></i> 个人资料</li>
                             <li class="yincang"><i class="el-icon-edit-outline"></i> 修改密码</li>
                             <li class="yincang"><i class="el-icon-switch-button"></i> 退出</li>
                           </ul>
                        <a slot="reference" class="nav-link navbar-avatar" data-toggle="dropdown" href="#" aria-expanded="false" data-animation="scale-up" role="button">
						    <span class="avatar avatar-online">
							    <img id="navbar-userimg" src="http://www.cxcyedu.com/global/portraits/1.jpg">
                                <i></i>
                                <!-- 头像 -->
						    </span>
						    <span style="font-size: 16px;" id="userInfo" data-user-id="6">演示机构</span>
				        </a>
                        </el-popover>
                        
                    </li>
                </ul>
            </div>
        </nav>
        <div class="page">
            <div class="page-content">
        	    <div class="panel">
                 <div class="panel-heading">
					<input type="hidden" id="stageliveId" value="208">
					<input type="hidden" id="stageliveStatus" value="1">
					<h3 class="panel-title p-15">
						[彩排模拟测试]2022第八届互联网+江苏省创新创业大赛
						<span class="badge badge-primary p-5"><i class="iconfont icon-jiangbei" style="margin-right:5px"></i>2022第八届互联网+江苏省创新创业大赛</span>
						<div style="font-size: 16px;" class="mt-10 mb-0">
							<span class="mr-10">
								<span style="font-weight: bold;">评审组：</span>
								<span class="badge badge-warning p-5">第七评审组</span>
							</span>
							<span>
								<span style="font-weight: bold;">现场阶段：</span>
								<span class="badge badge-info">路演</span>+<span class="badge badge-info">答辩</span>+<span class="badge badge-info">评审</span>+<span class="badge badge-info">公示</span>
							</span>
						</div>
					</h3>
					<div class="panel-actions">
						<button type="button" class="btn btn-icon btn-warning btn-sm" @click="openSmallWindows('/tyds/index.html/#/touping?stageliveId=208')"><i class="el-icon-video-camera-solid"></i> 投屏</button>
						<button type="button" class="btn btn-icon btn-success btn-sm" @click="openSmallWindows('http://www.cxcyedu.com/tyds/m/ydd/main.html?stageliveId=208')"><i class="el-icon-film"></i> 大赛得分榜列表</button>
						<!--<button type="button" class="btn btn-icon btn-success btn-sm" onclick="openSmallWindows('/tyds/index.html/#/defenbang?stageliveId=208')"><i class="fa fa-sort-amount-desc" aria-hidden="true"></i> 本场得分榜</button> -->
						<button type="button" class="btn btn-icon btn-success btn-sm" @click="openSmallWindows('http://www.cxcyedu.com/tyds/m/ydd/index.html?stageliveId=208')"><i class="el-icon-film" ></i></button>
						<button type="button" class="btn btn-icon btn-success btn-sm" @click="openSmallWindows('/tyds/index.html#/Datacenter?stageliveId=208')"><i class="el-icon-s-data" ></i> 数据中心</button>
						<button type="button" class="btn btn-icon btn-danger btn-sm" @click="updateStatus(-1)"><i class="el-icon-circle-close"></i> 结束</button>
		        	</div>
				 </div>
				 
			    </div>
			    <div class="panel panel-bordered mb-15">
				    <div class="panel-heading">
				    	<div class="panel-title py-10">
                            <span style="font-weight: bolder;">阶段控制</span>
						    <span class="ml-10" style="font-size: 14px;">
                                当前阶段
                                <span id="currPartShow" class="badge badge-info">1</span> / 共4阶段</span>
				    	</div>
				    	<div class="panel-actions">
						    <span id="curtproj" class="badge badge-dark">智子锁—智能多协议量子密码系统</span>
		            	</div>
				    </div>
				    <div class="panel-body container-fluid py-10">
					<div class="example-blocks">
						<ul id="part-ul" class="blocks-5" data-partcnt="4" data-currpart="1" data-partstate="0">
							<li id="part_0" style="border-right: 1px solid #ccc;cursor:pointer;" data-partnum="0" data-partname="转场等待" data-partsec="0">
								<div class="example-col">
									<div @click="open('转场阶段')" class="card card-inverse overlay h-full border">
										<img class="card-img overlay-figure w-full" id="jdbg0" src="http://www.cxcyedu.com/assets/images/live-bg01.png" height="160px;" alt="...">
										<div class="card-img-overlay text-center vertical-align overlay-background">
											<div class="vertical-align-middle">
												<div class="card-text" style="font-size: 20px;">
													<i class="el-icon-time" aria-hidden="true"></i>
												</div>
												<h5 class="card-title">转场等待</h5>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li id="part_1" class="part-li active" style="cursor:pointer;" data-partnum="1" data-partname="路演阶段" data-partsec="300">
								<div class="example-col">
									<div class="card card-block bg-info border">
										<div @click="open('路演阶段')" class="counter counter-lg counter-inverse" :class="{'active': active=='路演阶段'}" key="box3">
											<div class="counter-number">
												<i v-if="active!='路演阶段'" class="el-icon-data-analysis md-tv-list" aria-hidden="true"></i>
											</div>
											<span class="counter-label text-uppercase">路演阶段</span>
										</div>
										<div class="counter-inverse2">
						                    <div id="partdown_1" class="countDown">{{time}}</div>
						                    <div class="btn-box">
						                        <button type="button" class="btn btn-icon btn-success btn-sm" id="partstate_1" @click="timedown">开始</button>
						                        <button type="button" class="btn btn-icon btn-warning btn-sm" @click="resetPartStatus(1)">复位</button>
						                    </div>
						                </div>
									</div>
								</div>
							</li>
							<li id="part_2" class="part-li " style="cursor:pointer;" data-partnum="2" data-partname="答辩阶段" data-partsec="300">
								<div class="example-col">
									<div class="card card-block bg-info border">
										<div @click="open('答辩阶段')" class="counter counter-lg counter-inverse" :class="{'active': active=='答辩阶段'}" key="box2">
											<div class="counter-number">
												<i  v-if="active!='答辩阶段'" class="el-icon-data-analysis md-tv-list" aria-hidden="true"></i>
											</div>
											<span class="counter-label text-uppercase">答辩阶段</span>
										</div>
										<div class="counter-inverse2">
						                    <div id="partdown_2" class="countDown">{{time}}</div>
						                    <div class="btn-box">
						                        <button type="button" class="btn btn-icon btn-success btn-sm" id="partstate_2" @click=" timedown">开始</button>
						                        <button type="button" class="btn btn-icon btn-warning btn-sm" @click="resetPartStatus(2)">复位</button>
						                    </div>
						                </div>
									</div>
								</div>
							</li>
							<li id="part_3" class="part-li " style="cursor:pointer;" data-partnum="3" data-partname="评审阶段" data-partsec="60">
								<div class="example-col">
									<div class="card card-block bg-info border">
										<div @click="open('评审阶段')" class="counter counter-lg counter-inverse" :class="{'active': active=='评审阶段'}" key="box1">
											<div class="counter-number">
												<i  v-if="active!='评审阶段'" class="el-icon-data-analysis md-tv-list" aria-hidden="true"></i>
											</div>
											<span class="counter-label text-uppercase">评审阶段</span>
										</div>
										<div class="counter-inverse2">
						                    <div id="partdown_3" class="countDown">{{time}}</div>
						                    <div class="btn-box">
						                        <button type="button" class="btn btn-icon btn-success btn-sm" id="partstate_3" @click=" timedown">开始</button>
						                        <button type="button" class="btn btn-icon btn-warning btn-sm" @click="resetPartStatus(3)">复位</button>
						                    </div>
						                </div>
									</div>
								</div>
							</li>
							<li id="part_4" class="part-li " style="cursor:pointer;" data-partnum="4" data-partname="公示阶段" data-partsec="3">
								<div class="example-col">
									<div class="card card-block bg-info border">
										<div @click="open('公示阶段')" class="counter counter-lg counter-inverse" :class="{'active': active=='公示阶段'}">
											<div class="counter-number">
												<i  v-if="active!='公示阶段'" class="el-icon-data-analysis md-tv-list" aria-hidden="true"></i>
											</div>
											<span class="counter-label text-uppercase">公示阶段</span>
										</div>
										<div class="counter-inverse2">
											<div class="btn-box2">
						                        <span class="badge badge-success badge-lg p-10">
						                        	<div class="mb-10">最终得分</div>
						                        	<div id="score_4">--</div>
						                        </span>
						                    </div>
						                </div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				    </div>
				 <div class="panel-content">
                    <div class="main-body">
                        <div class="touping">
                           <img src="http://www.cxcyedu.com/assets/images/live-bg01.png" alt="">
                           <div class="tpcontent">
                               <div class="text">转场等待,请稍后</div> 
                               <div class="intrduce">
                                    <div class="next">>下一个路演项目<</div>
                                    <div class="xiangmu"><span>中国江苏</span> 智子锁—智能多协议量子密码系统</div>
                               </div>
                           </div>
                        </div>
                    </div>
                    <div class="col-md-5 pl-0">
                        <div class="fixed-table-toolbar">
                            <div class="bs-bars float-left">
                                <div class="btn-group" id="tableToolbar" role="group">
			                        <el-tooltip content="上一个项目" placement="top">
                                        <el-button><i class="el-icon-caret-left"></i></el-button>
                                    </el-tooltip>
                                    <el-tooltip content="排序" placement="top">
                                        <el-button><i class="el-icon-s-fold"></i></el-button>
                                    </el-tooltip>
                                    <el-tooltip content="下一个项目" placement="top">
                                        <el-button><i class="el-icon-caret-right"></i></el-button>
                                    </el-tooltip>
                                    <el-tooltip content="导出" placement="top">
                                        <el-button><i class="el-icon-upload"></i></el-button>
                                    </el-tooltip>
		                	    </div>
                            </div>
                            <div class="float-right search btn-group">
                                <input class="form-control search-input" type="text" placeholder="搜索">
                            </div>
                        </div>
                        <div class="datatable" style="text-align: center;">
                             <el-table height="447" :data="tableData" border style="width: 100%;height:100%;font-size: 16px;" 
                             :cell-style="{textAlign: 'center',fontWeight: '500'}" :header-cell-style="{textAlign: 'center',fontWeight: '600'}"
                             cell-class-name='cellname'>
                              <el-table-column type="index" label="序号" width="60"></el-table-column>
                              <el-table-column prop="name" label="项目名称" width="375"></el-table-column>
                              <el-table-column  prop="score"  label="最终得分"></el-table-column>
                               <el-table-column label="操作">
                                    <template slot-scope="scope">
                                     <el-button v-if="change!==scope.$index" :key="scope.$index"
                                       size="mini" @click="Change(scope.$index)"
                                       ><i class="el-icon-sunny" style="color:blue"></i></el-button>
                                        <el-button v-else type="danger" disabled><i class="el-icon-loading"></i>当前项目</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                 </div>
                        
			    </div>
		    </div>
	    </div>
        <footer class="site-footer">
	       
        </footer>
    </div>
</template>


<script>
 export default {
    data() {
      return {
        tableData: [{
          name: '智子锁—智能多协议量子密码系统',
          score: '--',
          opreat:'--',
        }, {
          name: '庄周化蝶:基于MR的仿真定制平台',
          score: '--',
          opreat:'--',
        }, {
          name: '医网智联',
          score: '--',
          opreat:'--',
        }, {
          name: '智慧皮肤——皮肤病智能预诊系统',
          score: '--',
          opreat:'--',
        }, {
          name: '复杂散装商品自助估价系统',
          score: '--',
          opreat:'--',
        }, {
         name: '5G无人车校园配送平台',
          score: '--',
          opreat:'--',
        }, {
         name: '智慧体育校园--集体锻、体测、数据分析三位一体的智能化运动视...',
          score: '--',
          opreat:'--',
        }, {
         name: '车芯忆”国产化车载存储芯片材料引领者',
          score: '--',
          opreat:'--',
        }, {
         name: '智测未来--智能回弹仪系统',
          score: '--',
          opreat:'--',
        }, {
         name: '智能物联空气净化系统',
          score: '--',
          opreat:'--',
        }, {
         name: '遇见南朝——南朝石刻艺术数字化建设开发',
          score: '--',
          opreat:'--',
        }, {
         name: '勇敢“芯”，争做激光探测领域新势力',
          score: '--',
          opreat:'--',
        }],
        active:localStorage.getItem('activeName')?localStorage.getItem('activeName'):"",  //阶段控制
         min: localStorage.getItem('activeMin')?localStorage.getItem('activeMin'):0,       //用于显示的倒计时分钟
         sec: localStorage.getItem('activeSec')?localStorage.getItem('activeSec'):0,      //倒计时秒针
         time: localStorage.getItem('activeTime')?localStorage.getItem('activeTime'):"",  //用来显示倒计时的时间
         //定义的初始时间
         min1: 5,    //路演倒计时分钟
         sec1: 0,     //路演倒计时秒针
         min2: 5,    //答辩倒计时分钟
         sec2: 0,     //答辩倒计时秒针
         min3: 1,    //评审倒计时分钟
         sec3: 0,     //评审倒计时秒针
        change:'',
      }
    },
    methods:{
        //全屏
        handleFullScreen() {
                let element = document.documentElement;
                   if (this.fullscreen) {
                     if (document.exitFullscreen) {
                       document.exitFullscreen();
                     } else if (document.webkitCancelFullScreen) {
                       document.webkitCancelFullScreen();
                     } else if (document.mozCancelFullScreen) {
                       document.mozCancelFullScreen();
                     } else if (document.msExitFullscreen) {
                       document.msExitFullscreen();
                     }
                   } else {
                     if (element.requestFullscreen) {
                       element.requestFullscreen();
                     } else if (element.webkitRequestFullScreen) {
                       element.webkitRequestFullScreen();
                     } else if (element.mozRequestFullScreen) {
                       element.mozRequestFullScreen();
                     } else if (element.msRequestFullscreen) {
                       // IE11
                       element.msRequestFullscreen();
                     }
                   }
                   this.fullscreen = !this.fullscreen;
            },
        //阶段点击事件
        selected(name) {
                 this.active = name; 
             },
        //切换阶段
        open(name) {
            this.$confirm('是否切换到该【'+name+'】', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
               //高光   
              localStorage.setItem('activeName',name)
              this.selected(name)
              //时间的不同展示
              this.showTime(name)
              this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              }).catch(() => {        
              });
        },
        Change(index){
            this.change=index
        },
        openSmallWindows(wz){
            window.open(wz, '_blank','location=no');
        },
          //倒计时
         timedown() {
          if (this.sec > 0) {
           this.sec--;
          } else {
           this.min--;
           this.sec = 59;
          }
          this.time = (this.min >= 10 ? this.min : "0" + this.min) +
          ":" +
          (this.sec >= 10 ? this.sec : "0" + this.sec);
          localStorage.setItem('activeMin',this.min)        
          localStorage.setItem('activeSec',this.sec)        
          localStorage.setItem('activeTime',this.time)      
          if (this.min == 0 && this.sec == 0) {return }  },
        //时间的不同展示    
        showTime(name){        
            if(name=='路演阶段'){            
                this.sec=this.sec1            
                this.min=this.min1            
                this.time= (this.min >= 10 ? this.min : "0" + this.min) +
                ":" +            
                (this.sec >= 10 ? this.sec : "0" + this.sec);
                }        
            if(name=='答辩阶段'){            
                this.sec=this.sec2            
                this.min=this.min2            
                this.time= (this.min >= 10 ? this.min : "0" + this.min) +            
                ":" +            
                (this.sec >= 10 ? this.sec : "0" + this.sec);        
                }        
            if(name=='评审阶段'){            
                this.sec=this.sec3            
                this.min=this.min3            
                this.time= (this.min >= 10 ? this.min : "0" + this.min) +            
                ":" +            
                (this.sec >= 10 ? this.sec : "0" + this.sec);        
                }        
                localStorage.setItem('activeMin',this.min)        
                localStorage.setItem('activeSec',this.sec)        
                localStorage.setItem('activeTime',this.time)    
        },
        //复位    
        resetPartStatus(num){        
            if(num==1){            
                this.showTime('路演阶段')        
                }        
            if(num==2){            
                this.showTime('答辩阶段')        
            }        
            if(num==3){            
                this.showTime('评审阶段')
                }    
            }
    },
    mounted(){
        // this.handleFullScreen()
    },
  }
</script>


<style scoped>
/* .element::-webkit-scrollbar { width: 0 !important } */
.main {
    background-color: #f1f4f5;
}
.main::-webkit-scrollbar {
     width: 0 !important;
    display: none;
        
}
*{
    padding: 0;
    margin: 0;
}
 ::-webkit-scrollbar {
    display: none;
  }

a{
    color: #FFF 
}
.navbar{
    width: 100%;
    height: 60px;
    background-color: #3e8ef7;
    border-color: rgba(0,0,0,.1);
    display: flex;
    justify-content: center;
    color: #FFF;
}
.navbar-header{
    display: flex;
    align-items: center;
}
.navbar-logo{
    width: 24px;
    margin-top: -0.1rem;
}
.navbar-text{
    margin-left: 0.1rem;
    font-size: 18px;
}
.navbar-header a{
    color: #fff;
}
.navbar-content{
    width: 70%;
}
.navbar-content ul{
    float: right;
    display: flex;
    align-items: center;
    height: 100%;
}

.el-icon-full-screen{
    font-size: 22px;
    padding: 0px 10px;
    height: 100%;
    line-height: 60px;
    margin-right: 10px;
}
.navbar-content ul li{
    height: 100%;
    line-height: 60px;
}
.navbar-content ul li:hover{
   background-color: #1770b8;
    cursor: pointer;
}
.avatar{
    position: relative;
    display: inline-block;
    width: 30px;
    white-space: nowrap;
    vertical-align: bottom;
    border-radius: 50%;
    margin-right: 5px;
}
.avatar img{
    width: 100%;
    border-radius: 50%;
}
.avatar i{
    background-color: #22bb0f;
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 5px;
    height: 5px;
    border: 2px solid #fff;
    border-radius: 100%;
}

.page{
    margin: 0 auto;
    background: #f1f4f5;
    position: relative;
    min-height: calc(100% - 44px);
}

@media (min-width: 1600px){
    .page {
    max-width: 1600px;
}
}

@media (min-width: 1200px) and (max-width: 1599px){
    .page {
        width: 1200px;
    }
}
.page-content{
    /* width: 1600px; */
     margin: 0 auto;
}
.panel {
    position: relative;
    margin: .5rem;
    background-color: #fff;
    border: 0 solid transparent;
    /* border-radius: 0.286rem; */
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.panel-heading {
    border-top-left-radius: 0.214rem;
    border-top-right-radius: 0.214rem;
    position: relative;
    padding: 0;
    border-bottom: 1px solid transparent;
}
.panel-title {
    display: block;
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    color: #37474f;
}

.panel-actions {
    position: absolute;
    top: 50%;
    right: 30px;
    z-index: 1;
    margin: auto;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);
    box-sizing: border-box;
}
.badge {
    font-weight: 500;
}
.badge-dark {
    color: #fff;
    background-color: #526069;
}
.panel-body {
    position: relative;
    padding: 20px 30px;
}

.example-blocks ul{
    display: flex;
   list-style: none;
    justify-content: space-around;
} 
.example-blocks ul li{
    width: 20%; 
    /* display: inline-block; */
    font-size: 1rem;
    vertical-align: top;
    /* padding-right: 1.0715rem;
    padding-left: 1.0715rem;
    margin-bottom: 2.143rem; */
    
    padding: 0 .33rem;
} 
.card{
    margin-bottom: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    overflow: hidden;
    height: 160px;
    padding: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}
.bg-info {
    color: #fff;
    background-color: #10a0be;
}
.vertical-align-middle {
    vertical-align: middle;
}
.vertical-align-bottom, .vertical-align-middle {
   font-size: .5rem;
    color: #fff;
}
.overlay-background {
    background: rgba(0,0,0,.5);
}
.text-center {
    text-align: center!important;
}

.vertical-align {
    font-size: 0;
}
.text-center {
    text-align: center!important;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
}
.card-title{
	color: #FFF;
}
.card .counter-inverse {
    flex: 1;
    width: 100%;
	height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	transition: all 13s;
	font-size: 22px;
}
.active{
    height: auto !important;
}
.card .counter-inverse2 {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eee;
    position: relative;
    top: 0px;
	transition: all 0.3s;
}
.card:hover{
	transform: scale(1.14);
	transition: all 0.5s;
}
.border {
    border: 1px solid #e4eaec!important;
}
.border:hover {
    border: 1px solid #07b6f1!important;
    border-radius: 5px
}
.el-icon-data-analysis{
	font-size: xx-large;
}
/* .bg-info :col{
    color: #fff;
    background-color: #0bb2d4;
} */
.card .counter-inverse2 .countDown {
    font-size: 30px;
    color: #000;
    display: flex;
    font-weight: bold;
    justify-content: center;
    flex: 1;
}
.card .counter-inverse2 .btn-box {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.btn-success {
    color: #fff;
    background-color: #11c26d;
    border-color: #11c26d;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-success:hover{
   background-color: #09cc6e;  
}
.btn-warning {
    color: #fff;
    background-color: #eb6709;
    border-color: #eb6709;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-warning:hover{
   background-color: #e46d17; 
}
.btn-danger {
    color: #fff;
    background-color: #ff4c52;
    border-color: #ff4c52;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.card .counter-inverse2 .btn-box button {
    margin: 10px auto;
}
.btn-icon.btn-sm, .btn.icon.btn-sm {
    padding: 0.1rem;
    font-size: 16px;
    border-radius: 3px;
    margin-left: 5px;
}
.btn-icon{
    line-height: 1em;
}
button{
	cursor: pointer;
	font-family: inherit;
	user-select: none;
    border: 1px solid transparent;font-weight: 300;
}



.panel-title>.badge {
    margin-left: 10px;
}
.p-5 {
    padding: 5px!important;
}
.badge {
    font-weight: 500;
}

.badge-primary {
    color: #fff;
    background-color: #298cf7;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    /* font-weight: 300; */
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 2px;
}

.mt-10 {
    margin-top: 10px!important;
}
.mr-10 {
    margin-right: 10px!important;
}
.badge-warning {
    color: #111;
    background-color: #ffc107;
}
.badge {
    font-weight: 500;
}
.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.panel>:not(.panel-loading):not(.collapsing) {
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
}
/* .panel-bordered>.panel-heading {
    border-bottom: 1px solid #e4eaec;
} */
.panel-bordered>.panel-body {
    padding-top: 30px;
}
.panel-heading+.panel-body {
    padding-top: 0;
}

.panel-body {
    position: relative;
    padding: 20px 30px;
}
.p-10 {
    padding: 10px!important;}

.touping{
    max-width: 1340px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
}
.touping img{
 width: 100%;
 height: 100%;
}
.tpcontent{
    margin-top: 10%;
    max-width: 1340px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
}
.tpcontent .text{
    color: #0bc0fb;
    font-size: 75px;
}
.intrduce{
    margin-top: 10%
}
.intrduce .next{
    color: #fff;
    font-size: 40px
}
.intrduce .xiangmu{
    color: yellow;
    font-size: 35px
}
.intrduce .xiangmu span{
    font-size: 35px;
    color: #fff;
    background: #0a4477;
    border: 1px solid #0bade7;
    border-radius: 8px;
    display: inline-block;
    padding: 10px;
}
.panel-content{
    display: flex;
    max-height: 520px;
}
.pl-0 {
    padding-left: 0!important;
}

@media (min-width: 768px){
.col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;}
}

.col-md-5{
    box-sizing: border-box;
    padding: 10px;
}


.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}
.el-tooltip{
    font-size: 22px;
    padding:3px 10px;
    color: #3e8ef7;
    border: 1px solid #3e8ef7;
    margin: 0 !important;
}
.el-tooltip:hover{
    background: #3e8ef7;
    color: #fff;
}
.search{
    margin-right: 10px;
}
.form-control {
    width: 100%;
    padding: 2% 0 2% 5%;
    color: #76838f;
    background-color: #fff;
    border: 1px solid #e4eaec;
    border-radius: 0.1rem;
}
.fixed-table-toolbar{
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
}


.yincang{
    font-size: 16px;
    padding: 0 5px 10px;
    /* text-align: center; */
}
.yincang:nth-child(3){
     padding: 10px 5px 0px;
    border-top: .5px solid #d9d9d9;
}
.yincang:hover{
   background-color: #d9d9d9;
   cursor: pointer;
}









@media (min-width: 1600px){
.site-footer {
    padding-left: -webkit-calc((100% - 1600px)/ 2 + 30px)!important;
    padding-left: calc((100% - 1600px)/ 2 + 30px)!important;
    padding-right: -webkit-calc((100% - 1600px)/ 2 + 30px)!important;
    padding-right: calc((100% - 1600px)/ 2 + 30px)!important;
}
}
.site-footer {
    padding: 10px 30px;
    /* background-color: #fff; */
    /* border-top: 1px solid #e4eaec; */
    height: 20px;
    -webkit-box-shadow: inset 0 0 44px rgb(0 0 0 / 2%);
    box-shadow: inset 0 0 44px rgb(0 0 0 / 2%);
}
</style>
